.srch-form {
    position: absolute;
    right: 0;
    top: 100%;
    margin-top: 25px;
    width: 600px;
    padding: 15px;
    margin-right: 30px;
    background: #fff;
    z-index: 9;
    display: none;
    // border-top: 2px solid #66cef6;
}

.srch-form .side-search {
    border-bottom: none;
    padding-bottom: 0;
    margin-bottom: 0;
}

.input-group {
    position: relative;
    display: table;
    border-collapse: separate;
}

.input-group .form-control:first-child {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.srch-form .search-wid {
    // border: 1px solid $primary_color;
    border-right: 1px solid transparent;
}

.input-group-addon:last-child {
    border-left: 0;
}

.btn-side-serach {
    font-size: 18px;
    color: #fff;
    background: #66ccf4;
    border: 1px solid #66ccf4;
    padding-left: 23px;
    padding-right: 23px;
}

.input-group-addon {
    padding: 6px 12px;
    font-size: 14px;
    font-weight: 400;
    line-height: 1;
   
    text-align: center;
    // background-color: $primary_color;
    // border: 1px solid $primary_color;
    border-radius: 4px;
    border: none;
    border-color: none !important;
}


.input-group-addon,
.input-group-btn {
    // width: 1%;
    white-space: nowrap;
    vertical-align: middle;
    padding: 0px 30px;
    border-top-right-radius: 5px !important;
    border-bottom-right-radius: 5px !important;
    margin-right: 10px;
}

.fa {
    display: inline-block;
    font: normal normal normal 14px / 1 FontAwesome;
    font-size: 14px;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    transform: translate(0, 0);
    color: #fff;
}

.searchform-result-icon {
    font-size: 16px;
    font-weight: 600;
    color: #fff;
    margin-right: 10px;
}




// ....................searchcop
.searchbcomp {
    background: #eee;
}

.search-images {
    width: 70px;
    height: 70px;
}

.search-cpomp-searchlist {
    display: flex;
    justify-content: start;
    align-items: center;
    margin: 10px;
}
.searchcomp-search {
    background: #fff;
    padding: 20px;
    margin-top: 15px;
    height: 70vh;
    overflow-y: scroll;
}
.search-result-title {
    padding-left: 10px;
}

.search-comp-main-searchbar {
    padding-top: 20px !important;


}


.searchcomp-search::-webkit-scrollbar {
    width: 4px;
    cursor: pointer;
}

/* Track */
.searchcomp-search::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
.searchcomp-search::-webkit-scrollbar-thumb {
    background: #c1c1c1;
}

.searchbar-main-comp {
    height: 100%;
}

.search-main-data {
    height: 100vh;
}

.search-closeicon {
    font-size: 30px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin-top: 5px;
}


//   mylocalflotist css
.hjhjhj {
    //    font-family: "Jost", sans-serif;
    font-weight: 400;
}