.footerSecondContainer {
    // background: $nav-bg;
    // color: $nav-text-color;
    padding-top: 50px;

    .apple_icon {
        width: 100%;
    }

    .footerLogoimage {

        object-fit: contain;
        max-width: 200px;
    }

    @media screen and (max-width:768px) {
        .footer-logo-main {
            display: flex;
            // justify-content: center;
            max-height: 70px;
            padding-bottom: 10px;
        }
    }

    .footer-details-content {
        // color: #c0c1c2;
        // font-family: Open Sans, sans-serif;
        font-size: 14px;
        font-weight: 400;
        margin-bottom: 0;
    }

    .footer_descip {
        // font-size: $s-size;
        padding-right: 10px;
        padding-top: 5%;
        // font-weight:400;
    }

    .footerHeading {
        font-weight: 700;
        font-size: $m-size;

        padding-bottom: 4%;
    }

    .facebook {
        width: 12.85px;
        height: 24px;
        padding-right: 15.5px;
        // color: red;
        // border: 1px solid black;
    }

    .massages {
        // font-weight: 700;
        // font-size: 14px;

        font-style: normal;

    }

    .contact {
        font-weight: 400;
        font-size: $s-size;

        //padding-left: 5%;
    }

    .footer_component {
        // float: right;
        text-align: center;
        //padding-top: 3%;
    }

    .appimg {
        display: flex;
        gap: 16px;
        width: 320px;
        height: 64px;

    }

    .middle_paragraph {
        // font-size: $s-size;
        // gap: 10px;

        padding: 0px 0px;
        display: flex;
        justify-content: center;
        flex-direction: row;


    }

    .dddddd {
        display: flex;
        align-items: center;
        padding: 15px 0px 10px 0;
        text-decoration: none;

        .footer-line {
            padding: 0px 10px;
        }
    }

    .dddddd:first-child {
        padding: 18px 10px 10px 0px;
    }

    .ourshop_com {
        font-style: normal;
        font-weight: 400;
        font-size: 12;
        // color: rgba(255, 255, 255, 0.6);
        //padding-left: 75%;
    }

    .secondfooterHeading {
        font-weight: 700;
        font-size: $m-size;

    }

    .Connectwithus {
        font-weight: 700;
        font-size: $m-size;
        //padding-top: 5%;
    }

    .Reachout {
        // font-size: $s-size;
        // font-weight: 400;
        margin-top: -4%;
    }

    @media screen and (max-width: 2560px) {

        .wearealways {
            font-weight: 700;
            font-size: 20px;
            padding-bottom: 4%;
            // padding-top: 5%;
        }


    }

    @media only screen and (max-width: 991px) {
        .maxWidthContainerFluid {
            max-width: 960px;
        }

        padding-top: 20px;

        .sosalmidiaimg {
            padding-bottom: 25px;
        }

        .footerHeading {
            padding-bottom: 0%;
        }
    }

    @media screen and (max-width: 1440px) {

        /* .secondfooterHeading {
            padding-top: 9%;
        } */
    }

    @media screen and (max-width: 1326px) {

        .wearealways {
            padding-top: 2%;
        }

    }

    @media screen and (max-width: 1024px) {

        .middle_paragraph {
            a {
                padding: 0px 11px;
            }

            a:first-child {
                padding-left: 0px;
                padding-right: 11px;
            }

            a:last-child {
                padding-left: 11px;
                padding-right: 0px;
            }
        }

        .wearealways {
            padding-top: 0%;
        }

    }

}

.policies-main {
    margin: 5%;
    font-size: 15px;
}

.policies-titles {
    padding-bottom: 4%;
}

.fotter-contact {
    border-top: 1px solid $white;
    border-bottom: 1px solid $white;
}

.footer-text-contant {
    padding: 5px 0px;
    font-size: 12px;
}

.footer-contact {
    display: flex;
    margin-bottom: 16px;
    position: relative;
    width: auto;
}

.footer-contact svg {
    display: block;
    height: 1em;
    position: relative;
    width: 1em;
}

.footer-icons-main {
    color: #569631;
    font-size: 24px;
}

.footer-contact .footer-contact-desc {
    flex-grow: 1;
}

.footer-contact .footer-contact-icon {

    font-size: 22px;
    margin-right: 20px;
}

.footer-contact h6 {
    // color: #fff !important;
    // font-family: Inter, sans-serif;
    // font-size: 16px;
    // font-weight: 600;
    margin-bottom: 5px;
}

.footer-contact p {
    // color: #c0c1c2;
    // font-family: Open Sans, sans-serif;
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 0;
}

.subscribe-email {
    color: rgb(206, 169, 169) !important;
}

.socialmrdia-div {
    display: flex;
    flex-direction: row;
}

.socialicons {
    font-size: 30px;
    margin: 10px;
}