.mobile-header {
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 999;
    // background: $header-bg;
    align-items: center;
    box-shadow: none;
    justify-content: space-evenly;
    padding-top: 7px;
    padding-bottom: 7px;
    .header-item-top2{
        height: 44px;
    }
    .logo {
        margin-top: 4px;
        margin-left: 10px;

        img {
            object-fit: contain;
            height: 50px;
            width: 100px;
            margin:-5px 0px 0px 10px
        }
    }

    .header_title {
        margin-top: 11px;
        margin-left: 15px;
    }

    .searchbar {
        margin-left: 15px;
        margin-right: 15px;
        padding-bottom: 15px;
    }

    .header-item-top {
        display: flex;
        margin-left: 15px;
        margin-right: 15px;
        justify-content: space-between;
    }

    .header-address {
        display: flex;
        margin-left: 15px;

        .location-marker {
            margin-top: 13px;
            margin-right: 4px;
            color: $white;
        }

        .header-address-line1 {
            color: $gray-20;
            font-size: $xs-size;
        }

        .header-address-line2 {
            font-size: $s-size;
            font-weight: 400;
            color: $white;
        }
    }

    .dropdown-up-title {
        color: $white;
        font-size: $xs-size;
    }

    #nav-dropdown-dark-example {
        color: $white;
        font-size: $s-size;
    }

    #nav-dropdown-search {
        color: $black;
        font-size: $s-size;
    }

    #search-button {
        background-color: $primary_color;
    }

    .input-group-text {
        border: none;
    }

    .cart {
        margin: 15px 10px 10px 10px;
        .custom-badge {
            margin-top: -13px;
            // background: $primary_color;
            border-radius: 50%;
            // color: #fff;
            // font-size: 12px;
            padding: 1px 6px;
            position: absolute;
            margin-left: -14px;
        }
    }
}

.navbar {
    border-bottom: solid 1px #EBEBEB;
}

.bottom-tab-nav {
    border-top: solid 1px #EBEBEB;
}

.nav-link,
.bottom-nav-link {
    color: #55575b;
}

.bottom-nav-link.active {
    color: #922c88;
}

.bottom-tab-label {
    font-size: 12px;
}

.bottom-tabs {
    background-color: $white;
    box-shadow: rgba(34, 34, 34, 0.12) 0px 2px 16px 2px;
    padding: 0px 16px 2px !important;
    min-height: 48px;
}