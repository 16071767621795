.register-container {
    margin: auto;
    width: 50%;
    border: 1px #ddd solid;
    margin-top: 50px;

    .register-wrap-container {
        justify-content: center;
        border-radius: 5px;
        -webkit-box-shadow: 0px 10px 34px -15px rgba(0, 0, 0, 0.24);
        -moz-box-shadow: 0px 10px 34px -15px rgba(0, 0, 0, 0.24);
        box-shadow: 0px 10px 34px -15px rgba(0, 0, 0, 0.24);

        .left-side {
            display: flex;
            align-items: center;
            text-align: center;
            background-image: linear-gradient(rgba(0, 0, 0, 0.5),
                    rgba(0, 0, 0, 0.5)), url("https://cdn-site.people.ai/2022/06/07100746/10-top-b2b-sales-strategies-for-2022-1170x780-1.png");
            background-size: cover;
            background-position: center;

            .text {
                color: #fff;

                .signin-btn {
                    border: 2px solid $primary_color;
                    border-radius: 24px;
                    color: #fff;
                    font-size: 16px;
                    font-weight: 700;
                    margin: auto;
                    text-align: center;
                    cursor: pointer;
                    padding: 4px;
                    width: 30%;
                }
            }
        }

        .right-side {
            padding: 2rem;
            // background: #fff;

            img {
                max-height: 150px;
                object-fit: contain;
            }

            h4 {
                // font-weight: 700;
            }

            h4 span {
                font-size: 16px;
                display: block;
                margin-top: 10px;
                font-weight: 400;
                color: rgba(0, 0, 0, 0.4);
            }

            .signupsec {
                margin-top: 2px;
                text-align: center;

                .DontAccount {
                    // color: #828282;
                    // font-size: 12px;
                    // font-weight: 400;
                }

                .signUpNavLink {
                    // color: $primary_color;
                    // font-family: Poppins, sans-serif;
                    // font-size: 12px;
                    // font-weight: 600;
                    margin-top: 20px;
                    margin-left: 5px;
                    text-decoration: none;
                }
            }

            .navlink-2 {
                margin-top: 0px;
            }
        }
    }
}

.login-container {
    margin: auto;
    width: 33%;
    border: 1px #ddd solid;
    margin-top: 50px;
}

.login-register-btn {
    width: 100%;
    margin-left: unset;
    .product-detail-button {
        margin-top: 15px !important;
        margin-right: unset;
    }
}

.normal-register-btn {
    justify-content: center;

    .product-detail-button {
        margin-top: 15px !important;
        // width: 50%;
    }
}

@media only screen and (max-width: 991px) {
    .register-container {
        width: auto;
        margin: 20px;
        .product-detail-button {
            width: 100% !important;
            margin-left: unset;
        }
    }

    .left-side {
        padding: 1rem !important;
    }

    .right-side {
        padding: 1rem !important;
        border: none !important;
    }
}
.disable{
    pointer-events: none;
}
.captcha-div{
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}